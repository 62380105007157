import React from "react";
import DemoBtn from "../DemoBtn";

function SignUpAndLogin() {
  return (
    <div className="SignUpAndLoginContianer">
      <div>
        <div>
          <DemoBtn/>
        </div>
      </div>
    </div>
  );
}

export default SignUpAndLogin;
